import React from "react";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import { orderBy } from "lodash";
import moment from "moment";

import Image from "gatsby-image"
import styled from "styled-components"
const ThumbImage = styled(Image)`
  border-radius: 10px;
  max-width: 50px;
  margin-top: 10px;
`

function nodeToLink(node) {
    const {
        fields: { slug },
        frontmatter: { date, title, image }
    } = node;

    const betterDate = moment.utc(date).format("MMM D, YYYY");
    return (
        <Link to={slug} exact={true}>
          <span>{betterDate} :: {title}</span>
          <ThumbImage
            fluid={image.childImageSharp.fluid}
            alt="Banner Image"
          />
        </Link>
    );
}

export default function About({data}) {

    const { allMarkdownRemark: {
        edges,
    }} = data;

    return (
        <Layout title="About">
            <p>Half past seven.</p>
            <ul>
                {orderBy(edges, ({node}) => node.frontmatter.date, "desc")
                    .map(({node}, index) => (
                    <li id={`item-${index}`}>
                        {nodeToLink(node)}
                    </li>
                ))}
            </ul>
        </Layout>
    );
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC}) {
      edges {
        node {
          ...Meme
        }
      }
    }
  }
`;